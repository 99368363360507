import React, { useState } from "react";
import "../assets/scss/pages/home.scss";
import "remixicon/fonts/remixicon.css";

import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Container,
  Button,
} from "reactstrap";
import Marquee from "react-fast-marquee";

const benefits = [
  {
    label: "Claim Pump.fun 1st Page",
    text: "Every micro buy on Pump.fun propels your token to the front page, ensuring maximum exposure. Our strategic micro buy and sell intervals are designed to maintain your token's prime position",
    icon: "trophy-fill",
  },
  {
    label: "Capture the Buyers Attention",
    text: "When your token shines on the front page, it naturally grabs the attention of buyers. Use this prime visibility to entice and engage potential investors, making them eager to purchase",
    icon: "screenshot-2-fill",
  },
  {
    label: "KOTH and FOMO",
    text: "When you get Kings of The Hill badge, you create a wave of FOMO , driving eager buyers to your project. This heightened excitement will propel you toward the Raydium pool, maximizing your token’s potential.",
    icon: "rocket-2-fill",
  },
];

const wcus = [
  {
    label: "CHEAPEST ON THE MARKET",
    text: "Take advantage of our unbeatable pricing! For early users, we're offering an exclusive 25% discount. Plus, for our premium Telegram users, enjoy an incredible deal of 25% off, plus an additional 30% savings! ",
    icon: "money-dollar-circle-line",
  },
  {
    label: "CONFIGURABLE BUMP",
    text: "The BumpIsFun bot is designed for easy setup, allowing you to customize your bump intervals, micro buy amounts, and payment plans with just a few clicks. Plus, it comes equipped with Jito tip support to help you maximize the impact of your bumps",
    icon: "settings-fill",
  },
  {
    label: "EARN SOL",
    text: "Share your referral link and start earning SOL effortlessly! For every fee paid, you’ll receive an instant 30% payout. You can invite your college or your friend",
    icon: "wallet-3-fill",
  },
];

export default function Home() {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  return (
    <div className="home">
      <Navbar dark expand="md" container fixed="top">
        <NavbarBrand href="/">
          <h1>
            <span className="green">BUMP</span>IS
            <span className="green">FUN</span>
          </h1>
        </NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="ms-auto" navbar>
            <NavItem>
              <NavLink href="https://t.me/bumpisfun">Bump Bot</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="https://x.com/BumpIsFun">Twitter</NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
      <section className="hero">
        <Container>
          <div className="hero-container">
            <div className="hero-left">
              <span>Pump Fun Bump Bot</span>
              <h2>
                Your Shortcut
                <br />
                to the Top! 🚀
              </h2>
              <p>
                Supercharge your project with the cheapest, optimized bump bot.
                Customize bump speed, quantity, and Jito tip support. Dynamic
                pricing plan, customizable recipes and more
              </p>

              <Button color="success">🤖 @BumpIsFunBot</Button>
            </div>

            <div className="hero-right">
              <img src={require("../assets/images/hero.png")} />
            </div>
          </div>
        </Container>
      </section>

      <section className="marque">
        <Marquee className="marque-text">
          <span>
            EARLY USER DEAL - ONLY 0.075 SOL - ONLY 0.0525 SOL FOR TG PREMIUM
            ACCOUNT
          </span>
        </Marquee>
      </section>

      <section className="benefit">
        <Container>
          <div className="benefit-container">
            <span className="badges">Introduction</span>
            <h2 className="text-center">
              <span>Why Can </span>
              <span className="green">BUMP</span>IS
              <span className="green">FUN</span>
              <br />
              <span>{" Be the Shortcut to Your Token?"}</span>
            </h2>

            <p>
              Pump.fun offers unparalleled ease of use, making it challenging to
              claim the top spot. To rise above the competition, you need a
              unique strategy. Our bot is designed to execute micro buys and
              sells, ensuring your token shines at the number one position.
              Don't just compete—dominate the market with Pump.fun!
            </p>

            <div className="benefit-list">
              <div className="benefit-item" x-for={(benefit, i) in benefits}>
                <div className="icon-wrapper">
                  <i className={`ri-${benefit.icon}`} />
                </div>
                <h3>{benefit.label}</h3>
                <p>{benefit.text}</p>
              </div>
            </div>
          </div>
        </Container>
      </section>

      <section className="benefit">
        <Container>
          <div className="benefit-container">
            <span className="badges">About</span>
            <h2 className="text-center">
              <span>WHY </span>
              <span className="green">BUMP</span>IS
              <span className="green">FUN</span>
              <span>?</span>
            </h2>

            <p>
              We’re here to enhance your journey with a seamless experience and
              unparalleled convenience. Our bot is the perfect partner for your
              adventures on PumpFun
            </p>

            <div className="benefit-list">
              <div className="benefit-item" x-for={(benefit, i) in wcus}>
                <div className="icon-wrapper">
                  <i className={`ri-${benefit.icon}`} />
                </div>
                <h3>{benefit.label}</h3>
                <p>{benefit.text}</p>
              </div>
            </div>
          </div>
        </Container>
      </section>

      <section className="cta">
        <Container>
          <div className="cta-container">
            <h2>LET's GET STARTED</h2>
            <p>because...</p>
            <p>bump as cheap as 0.0525 SOL will be able to pump x1000++</p>
            <div className="cta-buttons">
              <Button
                color="dark"
                onClick={() => window.open("https://t.me/bumpisfunbot")}
              >
                Telegram Bot
              </Button>
              <Button
                color="dark"
                onClick={() => window.open("https://t.me/bumpisfun")}
              >
                Telegram Channel
              </Button>
              <Button
                color="dark"
                onClick={() => window.open("https://t.me/bumpisfunsupport")}
              >
                Support
              </Button>
              <Button
                color="dark"
                onClick={() => window.open("https://t.me/BumpIsFun")}
              >
                Twitter
              </Button>
            </div>
          </div>
        </Container>
      </section>
    </div>
  );
}
